import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import normalizeCss from 'normalize.css';

import { FOOTER_LINKS, FOOTERBAR_LINKS, ACCOUNT_TYPE } from '../../constants';
import withResponsiveWrapper from '../../decorators/withResponsiveWrapper';
import s from './Layout.scss';
import Header from '../Header';
import Footer from '../Footer';
import FooterBar from '../FooterBar';
import RedeemOverview from '../RedeemOverview';
import Notifications from '../Notifications';
import ServiceMessage from '../ServiceMessage';

// Clean up with proper props

const Layout = ({
  logoUrl,
  showWhiteLabelLogoUrl,
  children,
  footer,
  footerBar,
  redeemOverview,
  logo,
  isMobile,
  mode,
  showServiceMessage,
  showFullHeader,
  showGlobalLink,
}) => {
  return (
    <div className={s.root} id="root">
      <div className={s.top}>
        <Header
          logo={showWhiteLabelLogoUrl ? logoUrl : logo}
          showFullHeader={showFullHeader}
          showGlobalLink={showGlobalLink}
        />
        {showServiceMessage && <ServiceMessage />}
      </div>
      <div
        id="main"
        className={cx(
          s.main,
          mode === ACCOUNT_TYPE.REDEEM && s.mode_redeem,
          showServiceMessage && s.hasServiceMessage,
        )}
      >
        {children}
      </div>
      {footer && (
        <Footer
          logoUrl={logoUrl}
          showWhiteLabelLogoUrl={showWhiteLabelLogoUrl}
          links={FOOTER_LINKS}
        />
      )}
      <div className={s.bottom}>
        {redeemOverview && (
          <RedeemOverview
            productPage={isMobile && redeemOverview === 'productPage'}
          />
        )}
        {/* Always diplay footerBar when not on mobile. Would rather set this in the route action but no access to responsiveWrapper there for now */}
        {(footerBar || !isMobile) && <FooterBar links={FOOTERBAR_LINKS} />}
      </div>
      <Notifications />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  footerBar: PropTypes.bool,
  footer: PropTypes.bool,
  logo: PropTypes.string,
  redeemOverview: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  showServiceMessage: PropTypes.bool.isRequired,
  showFullHeader: PropTypes.bool,
  showGlobalLink: PropTypes.bool,
  logoUrl: PropTypes.string,
  showWhiteLabelLogoUrl: PropTypes.bool,
};

Layout.defaultProps = {
  footerBar: true,
  footer: true,
  logo: undefined,
  redeemOverview: false,
  showFullHeader: true,
  showGlobalLink: false,
  logoUrl: null,
  showWhiteLabelLogoUrl: false,
};

const mapState = state => {
  const {
    config: {
      salesChannelId,
      salesChannels,
      salesChannel: { group: salesChannelGroup },
    },
    message,
    user,
    intl: { countryCode },
    basket: { vouchers },
  } = state;
  return {
    showServiceMessage: message[countryCode].showMessage,
    mode: user.mode,
    logo:
      salesChannelId &&
      salesChannels[salesChannelId] &&
      salesChannels[salesChannelId].logoUrl,
    salesChannelGroup,
    vouchers,
  };
};

export default compose(
  withResponsiveWrapper(),
  withStyles(normalizeCss, s),
  connect(mapState),
)(Layout);
