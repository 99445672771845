/* eslint-disable jsx-a11y/label-has-for */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import s from './TextInput.scss';
import Icon from '../Icon';
import TooltipIcon from '../TooltipIcon';

export class UnconnectedTextInput extends PureComponent {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    const { autoFocus } = this.props;

    if (autoFocus) {
      this.inputRef.current.focus();
    }
  }

  render() {
    const {
      label,
      showLabel,
      type,
      disabled,
      icon,
      hasError,
      multiline,
      fluid,
      onChange,
      rootClassName,
      innerRef,
      autoFocus,
      value,
      tooltip,
      // eslint-disable-next-line react/prop-types
      validationCallback,
      dispatch,
      tooltipTranslationKey,
      ...props
    } = this.props;
    const classes = cx(
      s.root,
      disabled && s.disabled,
      icon && s.hasIcon,
      fluid && s.fluid,
      hasError && s.hasError,
      rootClassName && rootClassName,
    );
    const labelClass = cx(
      showLabel ? s.label : 'visually-hidden',
      props.required && s.required,
    );

    return (
      <label className={classes}>
        {icon && <Icon name={icon} className={s.icon} />}
        {label && <span className={labelClass}>{label}</span>}
        {tooltip && (
          <TooltipIcon iconClassName={s.tooltipIcon} tooltip={tooltip} />
        )}
        {multiline ? (
          <textarea
            disabled={disabled}
            onChange={({ target }) => onChange(target.value)}
            value={value === null ? undefined : value}
            ref={autoFocus ? this.inputRef : innerRef}
            {...props}
          />
        ) : (
          <input
            ref={autoFocus ? this.inputRef : innerRef}
            type={type}
            disabled={disabled}
            onChange={({ target: { value: val, files } }) =>
              onChange(val, files)
            }
            value={value === null ? undefined : value}
            {...props}
          />
        )}
        {hasError && <span className={s.error}>{hasError.message}</span>}
      </label>
    );
  }
}

UnconnectedTextInput.propTypes = {
  label: PropTypes.node,
  showLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  icon: PropTypes.string,
  hasError: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape()]),
  multiline: PropTypes.bool,
  fluid: PropTypes.bool,
  onChange: PropTypes.func,
  rootClassName: PropTypes.string,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]),
  autoFocus: PropTypes.bool,
  value: PropTypes.string,
  tooltipTranslationKey: PropTypes.string,
  tooltip: PropTypes.string,
  dispatch: PropTypes.func,
};

UnconnectedTextInput.defaultProps = {
  label: undefined,
  showLabel: false,
  type: 'text',
  disabled: false,
  icon: undefined,
  hasError: false,
  multiline: false,
  fluid: false,
  rootClassName: undefined,
  autoFocus: false,
  onChange: () => null,
  innerRef: null,
  value: undefined,
  tooltipTranslationKey: undefined,
  dispatch: undefined,

  // This is mapped from the redux store and should not be provided explicitly.
  // Provide a configurable 'tooltipTranslationKey' instead.
  tooltip: undefined,
};

const mapState = (state, props) => {
  const tooltip =
    state.config.presentations[state.intl.countryCode]?.[
      props.tooltipTranslationKey
    ];
  return { tooltip };
};

export default compose(withStyles(s), connect(mapState))(UnconnectedTextInput);
